import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

require('@/assets/main.scss')

var app = createApp(App).use(store).use(router)

app = app.mount('#app')

store.$app = app
store.dispatch('initLang')
